import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import styles from "./JobDetails.module.scss";
import "./JobDetails.css";
import { getJob } from "../../../api/sinapsi.services";
import Navigator from "../../navigator/Navigator";
import jobLevel from "../../../assets/joblevel.png";
import locationIcon from "../../../assets/location.png";
import jobTypeIcon from "../../../assets/jobtype.png";
import remoteJob from "../../../assets/remotejob.png";
import cityIcon from "../../../assets/city-solid.svg";

export default function JobDetails() {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const links = [
    { link: "/jobs", label: "jobListing" },
    { link: `/jobs/${jobId}`, label: "jobDetails" },
  ];
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJob(jobId).then((res) => {
      setJobDetails({
        ...res,
        isFullTime: res.contractDuration === "Full time",
      });
      setLoading(false);
    });
  }, [jobId]);

  // function buildDescription(details) {
  //   console.log(jobDetails);
  //   return details.description?.paragraphs.map((paragraph) => {
  //     if (paragraph.paragraphType === 0) {
  //       const lines = paragraph.content.split(/\r?\n/);
  //       return (
  //         <div>
  //           <h4 className="mt-4">{paragraph.title}</h4>
  //           {lines.map((line) => (
  //             <div className="mt-1 text-break text-justify">{line}</div>
  //           ))}
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div>
  //           <h4 className="mt-4">{paragraph.title}</h4>
  //           <ul>
  //             {paragraph.items.map((item) => (
  //               <li className="mt-1 text-break text-justify">{item}</li>
  //             ))}
  //           </ul>
  //         </div>
  //       );
  //     }
  //   });
  // }

  function BuildLocations() {
    return Object.keys(jobDetails.locations).map((key) => {
      return(
      <div className={"location"}>
        <div className={"panelTitle jdCountry"}>
          <img src={locationIcon} className={"globeImage"}></img>
          &nbsp;
          {key}
        </div>
        {jobDetails.locations[key].map((item) => {
          return(
            <div className={"cityName"}>
              <img src={cityIcon} className={"cityIcon"}></img>
              &nbsp;
              {item}
            </div>
          )
        })}
        </div>
      )
    });
  }

  return (
    <div>
      <Header
        isJobHeader={true}
        jobTitle={jobDetails.title}
        isRemote={jobDetails.workTypes}
        isFullTime={jobDetails.isFullTime}
        canApply={jobDetails.canApply}
        jobId={jobId}
      ></Header>
      {loading ? (
        <Loader loading={loading}></Loader>
      ) : (
        <div>
          <Container className="mb-4">
            <Navigator links={links}></Navigator>
            <Row>
              <Col className="leftPanel">
                <div className={`${styles.container} pl-2`}>
                  <div>
                    <h3>{t("jobInfo")}</h3>
                    <h4>{jobDetails.jobInfoTitle}</h4>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>
                        {t("experienceLevel")}
                      </div>
                      <div className={"panelTitle"}>
                        <img src={jobLevel}></img>
                        &nbsp;
                        <div>{jobDetails.seniority}</div>
                      </div>
                    </div>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("workTime")}</div>
                      <div className={"panelTitle"}>
                        <img src={jobTypeIcon}></img>
                        &nbsp;
                        {jobDetails.isFullTime ? t("fullTime") : t("partTime")}
                      </div>
                    </div>
                    {jobDetails.locations ?
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("location")}</div>
                      {BuildLocations()}
                    </div>
                    : null}
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("workType")}</div>
                      <div className={"panelTitle"}>
                        <img src={remoteJob}></img>{" "}
                        &nbsp;
                        {jobDetails.workTypes?.map((w) => (
                          <span>{w}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={9} className={styles.left}>
                <div className="js">
                <h4>
                  What is the impact you want to make?
                </h4>
                <p>
                  Unleash your biggest strengths, apply skills & knowledge, learn new things, connect with your peers and build your career with us!
                </p>
                

                <h3>
                  Why rinf.tech?
                </h3>
                <h4> 
                  #EngineerOfTheFuture, #PeopleofManyTalents 
                </h4>
                <ul>
                  <li>
                    At rinf.tech, you’ll encounter friendly people who are eager to explore and reinvent the world of technology.
                  </li>
                  <li>
                    We encourage ideas - we like to share and learn from each other. We’re all in for curious & ambitious people.
                  </li>
                </ul>
                <h4>
                  #GrowOpportunities
                </h4>
                <ul>
                  <li>
                    We continuously invest in developing core teams focused on technologies like Blockchain, AI, and IoT -
                    <a
                      target="_blank"
                      href="https://www.rinf.tech/careers/core-blockchain-and-ai-teams/"
                      rel="noreferrer"
                    >
                    &nbsp;
                    www.rinf.tech/careers/core-blockchain-and-ai-teams/
                    &nbsp;
                  </a>
                  </li>
                  <li>
                    Our Technical Management team, possesses a robust technical background. Many of our team members have advanced to strategic roles through internal promotions.
                  </li>
                  <li>
                    In a state of mutual willingness to share & grow, our RINFers commit to a minimum tenure of 2.5 years on a project.
                  </li>
                </ul>
                <h4>
                  #EngineeringExcellence
                </h4>
                <ul>
                  <li>
                    Fail fast, learn fast: we experiment, we iterate, we know when to stop and we don't repeat the same mistakes.
                  </li>
                  <li>
                    The right technology stack for the right problem: we don't force technology choices just because we know them; our focus is on solving problems, not on pushing predefined stacks.
                  </li>
                </ul>
                <h4>
                  #Innovation
                </h4>
                <ul>
                  <li>
                    Adapta Robotics is a successful spin-off born through an R&D project within rinf.tech
                    <a
                      target="_blank"
                      href="https://www.adaptarobotics.com/"
                      rel="noreferrer"
                      // style= {{"color": "rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important"}}
                    >
                    &nbsp;
                    www.adaptarobotics.com/
                  </a>
                  </li>
                </ul>
                

                <h3>
                  Why do we do what we do?
                </h3>
                <p>
                  We inspire one another to share our tech-works in this amazing and abundant world. So we became developers, innovators, thinkers, software builders, and hardware makers!
                </p>
                

                <h3>
                  Our Vision!
                </h3>
                <p>
                  Founded in 2006 with 650+ engineers & global presence (8 delivery centers in Europe & North America) we strive to become a leading East-European technology partner for growing organizations in need of digital transformation of their products and services!
                </p>
                

                {
                  jobDetails.description?.Paragraphs[0].items.length > 0 && 
                  <>
                    <h3>
                      What you’ll do
                    </h3>
                    <ul>
                      {jobDetails.description.Paragraphs[0].items.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                }
                

                {
                  jobDetails.description?.Paragraphs[1].items.length > 0 && 
                  <>
                    <h3>
                      What you need to be successful
                    </h3>
                    <ul>
                      {jobDetails.description.Paragraphs[1].items.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                }

                {
                  jobDetails.description?.Paragraphs[2].items.length > 0 && 
                  <>
                    <h3>
                      What we offer you to be successful
                    </h3>
                    <ul>
                      {jobDetails.description.Paragraphs[2].items.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                }
                
                <h3>
                  Next Steps for you!
                </h3>
                {jobId === "149805" ? (
                  <>
                    <ul>
                      <li>Selection method: Online application with Essay</li>
                      <li>For final-year students under 26 years based in Bucharest, Romania</li>
                      <li>Registration deadline: November 15, 2024</li>
                      <li>Start date: November 25, 2024</li>
                      <li>Duration: 6 months, 4h per day</li>
                      <li>Remote program</li>
                    </ul>
                    <h4>Essay Requirements</h4>
                    <ul>
                      <li><span className="fw-bold">Length:</span> 1000-1500 words</li>
                      <li><span className="fw-bold">Introduction:</span> Briefly introduce yourself and your academic background. Explain why you are interested in embedded systems and firmware development.</li>
                      <li><span className="fw-bold">Technical Interest:</span> Discuss your interest in microcontrollers and embedded systems. Explain any relevant coursework or projects you have undertaken.</li>
                      <li><span className="fw-bold">Hardware-Software Integration:</span> Describe your understanding of how hardware and software interact in embedded systems.</li>
                      <li><span className="fw-bold">Problem-Solving Attitude:</span> Describe a problem you’ve solved that you are most proud of. Explain the steps you took to solve it and what you learned from the experience.</li>
                      <li><span className="fw-bold">Future Aspirations:</span> Outline your career goals and how participating in this academy will help you achieve them. </li>
                    </ul>
                  </>
                ) : jobId === "149793" ? (
                  <>
                    <ul>
                      <li>Selection method: Online application with Essay</li>
                      <li>For final-year students under 26 years based in Bucharest, Romania</li>
                      <li>Registration deadline: November 15, 2024</li>
                      <li>Start date: November 25, 2024</li>
                      <li>Duration: 6 months, 4h per day</li>
                      <li>Remote program</li>
                    </ul>
                    <h4>Essay Requirements</h4>
                    <ul>
                      <li><span className="fw-bold">Length:</span> 1000-1500 words</li>
                      <li><span className="fw-bold">Introduction:</span> Briefly introduce yourself and your academic background. Explain why you are interested in AI development.</li>
                      <li><span className="fw-bold">Technical Interest:</span> Discuss a specific area of AI (e.g., Generative AI, Large Language Models, Computer Vision) that fascinates you. Explain any relevant coursework or projects you have undertaken.</li>
                      <li><span className="fw-bold">Mathematical Foundation:</span> Describe your understanding of key mathematical concepts and how they apply to AI.</li>
                      <li><span className="fw-bold">Problem-Solving Attitude:</span> Describe a problem you’ve solved that you are most proud of. Explain the steps you took to solve it and what you learned from the experience.</li>
                      <li><span className="fw-bold">Future Aspirations:</span> Outline your career goals and how participating in this academy will help you achieve them.</li>
                    </ul>
                  </>
                ) : jobId === "150343" ? (
                  <>
                    <ul>
                      <li>Selection method: Online application with Essay</li>
                      <li>For final-year students under 26 years based in Bucharest, Romania</li>
                      <li>Registration deadline: November 15, 2024</li>
                      <li>Start date: November 25, 2024</li>
                      <li>Duration: 6 months, 4h per day</li>
                      <li>Remote program</li>
                    </ul>
                    <h4>Essay Requirements</h4>
                    <ul>
                      <li><span className="fw-bold">Length:</span> 1000-1500 words</li>
                      <li><span className="fw-bold">Language:</span> English</li>
                      <li><span className="fw-bold">Format:</span> PDF with code and visual examples. Include portfolio/project demonstrations</li>
                      <li><span className="fw-bold">Technical Background:</span> Describe your computer vision experience and detail any VLM projects or experiments. List relevant frameworks used and explain your ML development workflow.</li>
                      <li><span className="fw-bold">Project Deep-Dive:</span> Present a visual AI project you built explaining the architecture decisions and describe optimization strategies. Share results and performance metrics.</li>
                      <li><span className="fw-bold">Problem-Solving Example:</span> Present a computer vision challenge detailing your debugging approach. Explain performance improvements and discuss scalability solutions.</li>
                      <li><span className="fw-bold">Business Understanding:</span> Describe potential VLM applications and compute resource planning. Show understanding of visual AI costs versus benefits, present innovative use cases.</li>
                    </ul>
                  </>
                ) : jobId === "150474" ? (
                  <>
                    <ul>
                      <li><span className="fw-bold">Application:</span>
                        <ul>
                          <li>CV</li>
                          <li>A cover letter highlighting your motivation to participate in the program</li>
                          <li>A relevant essay or technology project</li>
                          <li>Transcript of academic results</li>
                        </ul>
                      </li>
                      <li><span className="fw-bold">Selection process of the applicants:</span> Applications that include all required information and meet the participation conditions will enter the preselection process. During this phase, a team from rinf.tech will evaluate applications based on the announced criteria: innovation, academic performance, and technical projects.</li>
                      <li><span className="fw-bold">Final interviews:</span> Up to 5 finalists will be selected for the final online interviews, where they will present their contributions and vision for emerging technologies.</li>
                      <li><span className="fw-bold">Deliberation and Announcement of Winners:</span> The evaluation team will deliberate to select the two winners of the rinf.tech private scholarships. The winners will be publicly announced at a scholarship award ceremony.</li>
                    </ul>
                    <p className="fw-bold">*For students who pass the preselection stage but are not designated as winners, rinf.tech aims to provide financial support for development over a six-month period, with the goal of fostering the academic and professional growth of as many future leaders in the technology industry as possible.</p>
                    
                    <h4>Eligibility</h4>
                    <ul>
                      <li>For students under 26 years based in Romania</li>
                      <li>Registration deadline: November 15, 2024</li>
                      <li>Start date: November 25, 2024</li>
                    </ul>
                    <h4>Technology project / Essay Requirements</h4>
                    <ul>
                      <li><span className="fw-bold">Essay length:</span> 800-1000 words</li>
                      <li><span className="fw-bold">Introduction:</span> Provide a brief self-introduction and describe your academic journey. Explain why you are interested in technology.</li>
                      <li><span className="fw-bold">Technical Interests:</span> Discuss a specific area of technology that fascinates you.</li>
                      <li><span className="fw-bold">Practical Experience:</span> Describe any relevant courses or projects you have undertaken.</li>
                      <li><span className="fw-bold">Problem-Solving Attitude:</span> Describe the most important problem you have solved. Explain the steps you took to resolve it and what you learned from the experience.</li>
                      <li><span className="fw-bold">Future Aspirations:</span> Outline your career goals and how winning this scholarship will help you grow.</li>
                    </ul>
                  </>
                ) : jobId === "169171" ? (
                  <ul>
                    <li>
                      <span className="fw-bold">Apply Now</span> - Submit your CV
                    </li>
                    <li>
                      <span className="fw-bold">CV Screening</span> - We’ll review your CV to see if you’re the right fit.
                    </li>
                    <li>
                      <span className="fw-bold">HR Interview</span> - If selected, you’ll have an interview with our TA team.
                    </li>
                    <li>
                      <span className="fw-bold">Assessment Center</span> - We’ll select the top 10-15 candidates to join the TA Academy.
                    </li>
                    <li>
                      <span className="fw-bold">Start Date:</span> The TA Academy kicks off on <span className="fw-bold">April 22nd</span>!
                    </li>
                    <li>
                      <span className="fw-bold">Duration:</span> The Academy runs from <span className="fw-bold">April 22nd to May 30th.</span>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      Apply
                    </li>
                    <li>
                      CV screening
                    </li>
                    <li>
                      HR Interview
                    </li>
                    <li>
                      Technical Interview
                    </li>
                    <li>
                      Offer presented by our VP of Strategic Projects & CoFounder
                    </li>
                  </ul>
                )}

                <h4>
                  Meet us!
                </h4>
                <p>
                  Let's meet! We invite you to drop by anytime for a tour of our office, without any commitment. 
                </p>
                

                

                <h4>
                  Join the #PeopleOfManyTalents #EngineerOfTheFuture
                </h4>
                
                
                  {/* <h3>{t("jobDescription")}</h3>
                  <p className="text-justify">
                    We are thrilled to have you as our candidate! Now, applying is a breeze with our streamlined application process!
                    
                    {jobDetails.abstract}
                  </p> */}
                </div>
                {/* {buildDescription(jobDetails)} */}
                <div className="mt-4 jd_section">
                  {jobDetails.canApply ? (
                    <Link
                      className="nohover d-inline-block me-3"
                      to={`/jobs/${jobId}/apply`}
                    >
                      <button>{t("interested")}</button>
                    </Link>
                  ) : null}
                  <Link className="nohover d-inline-block" to={`/jobs`}>
                    <button className="third">{t("goback")}</button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer showJobs={true}></Footer>
        </div>
      )}
    </div>
  );
}
