import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./GdprConsent.module.scss";
import { getGdprConsent, submitGdprConsent } from "../../api/sinapsi.services";
import logo from "../../assets/logorinf-colour.png";
import Loader from "../loader/Loader";

export default function GdprConsent() {
  const { t } = useTranslation();
  const { gdprId } = useParams();
  const [state, setState] = useState({
    loadingGdpr: false,
    validated: false,
    submitting: false,
    submitted: false,
    showModal: false,
  });
  const [gdprDetails, setGdprDetails] = useState({});

  useEffect(() => {
    setState({ ...state, loadingGdpr: true });
    getGdprConsent(gdprId).then((result) => {
      setGdprDetails(result);
      setState({ ...state, loadingGdpr: false });
    });
  }, {});

  const renderInvalidPrivacyId = () => {
    return (
      <div className="text-center">
        <FontAwesomeIcon className="display-3 mb-2" icon={faFaceFrown} />
        <h2>{t("invalidNps")}</h2>
        <div>{t("invalidNpsMessage")}</div>
      </div>
    );
  };

  const renderValidPrivacy = () => {
    if (!gdprDetails.canAccept) {
      return (
        <div className="text-center">
          <FontAwesomeIcon className="display-3 mb-2" icon={faClipboardCheck} />
          <h2 className="text-center">{t("alreadySubmittedNps")}</h2>
        </div>
      );
    }
    return (
      <div>
        <h3 className="text-justify">
          {t("gdprConsent")}
        </h3>
        <Modal show={state.showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("errorGeneric")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("gdprPrivacyError")}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Form
          id="applicationForm"
          validated={state.validated}
          onSubmit={submitUserPrivacyConsent}
          className="mt-4"
        >
          <Form.Group className="mb-3" controlId="experienceNotes">
            <Form.Label className="d-block">
              <Form.Check.Input
                disabled={state.submitting}
                required={true}
                type="checkbox"
                rows={5}
              />
              {t("gdprAgree")}&nbsp;
              <a
                href="https://www.rinf.tech/terms-conditions/recruitment-privacy-notice/"
                target="_blank"
                className="nohover d-inline-block" rel="noreferrer"
              >
                {t("gdprPrivacyNotice")}
              </a>
              &#44;&nbsp;
              {t("gdprAgree2")}
            </Form.Label>
          </Form.Group>
        </Form>
        {gdprDetails.canAccept ? (
          <button onClick={submitUserPrivacyConsent}>
            {t("submitGeneric")}
          </button>
        ) : null}
      </div>
    );
  };

  function handleClose() {
    setState({ ...state, showModal: false });
  }

  const submitUserPrivacyConsent = (event) => {
    const form = document.getElementById("applicationForm");
    if (form.checkValidity() === false) {
      setState({ ...state, submitting: false, validated: true });
      event.preventDefault();
      event.stopPropagation();
    } else {
      setState({ ...state, submitting: true });
      const result = submitGdprConsent(gdprId)
        .then(() => {
          setState({ ...state, submitting: false, submitted: true });
        })
        .catch((ex) => {
          setState({ ...state, showModal: true });
        });
    }
  };

  if (state.loadingGdpr) {
    return <Loader loading={state.loadingGdpr}></Loader>;
  }
  if (state.submitted) {
    return (
      <div className="text-center my-4">
        <FontAwesomeIcon className="display-3 mb-2" icon={faClipboardCheck} />
        <div>{t("gdprSubmitted")}</div>
      </div>
    );
  }
  return (
    <Container>
      <div className="my-4">
        <img className={styles.logo} src={logo}></img>
      </div>
      {gdprDetails.invalidIdentifier
        ? renderInvalidPrivacyId()
        : renderValidPrivacy()}
    </Container>
  );
}
