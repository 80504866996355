import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      domain: "Domain",
      deliveryCenter: "Delivery Center",
      workType: "Work Type",
      workTime: "Work Time",
      contractDuration: "Contract Duration",
      seniority: "Seniority",
      city: "City",
      country: "Country",
      search: "Search",
      jobDescription: "Job Description",
      jobInfo: "Job Information",
      workingOn: "What you will be working on",
      mission: "The Mission",
      techStack: "Our Technological Stack",
      offerUs: "What you need to be successful",
      offerYou: "What we offer you",
      recruitmentProcess: "Our recruitment processes",
      meetUs: "Meet us",
      competence: "Competence",
      experienceLevel: "Level of Experience",
      industry: "Industry",
      state: "State/Province",
      zip: "Zip/Postal Code",
      onSite: "On-Site",
      remoteJob: "Remote",
      Senior: "Senior Level",
      Middle: "Middle Level",
      fullTime: "Full-time",
      partTime: "Part-time",
      interested: "I'm interested",
      companyName: "rinf.tech",
      contact: "Get in touch",
      jobListing: "Jobs",
      jobDetails: "Job Details",
      perfectJob: "Join us! We've got the perfect job for you!",
      viewOpenings: "View Openings",
      home: "Home",
      jobs: "Jobs",
      noMatchTitle: "There are no results that match your search.",
      noMatchSuggestion: "Please try different filter or search criteria.",
      joinUs: "Join Us",
      filters: "Filters",
      searchPlaceholder: "Search job title or skill",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      socialNetwork: "Social Network",
      linkedin: "LinkedIn",
      linkedinProfile: "LinkedIn Profile",
      submitJobTitle: "Autofill Application",
      submitJobSubtitle:
        "Upload your resume/cv in seconds with the autofill option.",
      basicInfo: "Basic Info",
      submit: "Submit Application",
      submitGeneric: "Submit",
      cancel: "Cancel",
      visitWebsite: "Visit website",
      viewJobs: "View all jobs",
      phone: "Phone Number",
      captcha: "Captcha",
      reloadCaptcha: "Reload Captcha",
      enterCaptcha: "Enter Captcha Value",
      resumeAttachment: "Attachment Information",
      resume: "Resume",
      jobApplication: "Job application",
      openJobApplication: "Open job application",
      gdprConsentTitle: "Privacy Policy",
      privacyNotice: "Privacy Notice",
      gdprConsent:
        "By applying for this position, I confirm that I have read and understood the provisions of the recruitment privacy notice",
      gdprConsent2:
        "regarding the processing of personal data for recruitment purposes.",
      gdprConsentPeriod:
        "I hereby give my explicit consent to store and process my personal data included in my CV and any other documents provided, in the company's database, to be considered for other employment opportunities that may arise over the next 3 years.",
      openGdprConsent:
        "By checking the box below, you provide consent for us to store and process your personal data for the purpose of considering and contacting you for future employement opportunities.",
      npsGreeting: "Hi {{who}},",
      npsMessage:
        "Kindly spare a moment to share your feedback with us, allowing us to further enhance our services. Rest assured, our team will carefully review each response. Your honest thoughts and insights are invaluable to us. Thank you.",
      invalidNps: "Oops, this link is not valid.",
      invalidNpsMessage: "Please use the link you received in your email.",
      alreadySubmittedNps: "You have already submitted your review.",
      npsExperienceScaleDescription:
        "How would you rate your experience with rinf.tech as a candidate?",
      npsExperienceScaleDescription2:
        "How would you rate your experience with the Talent Acquisition consultant covering your job opening?",
      npsExperienceImprovement: "How can we improve our rating?",
      npsExperienceDescription: "What did you like about rinf.tech?",
      npsSubmitted:
        "Thank you for submitting your feedback. Rest assured, our team will carefully review each response.",
      gdprSubmitted: "Thank you for approving our privacy terms.",
      gdprTerms: "Terms and conditions",
      gdprContactPermission: "Contact permission",
      gdprAgree:
        "I hereby give my explicit consent to store and process my personal data included in my CV and any other documents provided in rinf.tech's database in compliance with",
      gdprAgree2:
        "for the purpose of considering and contacting me regarding future employment opportunities.",
      gdprPrivacyNotice: "Recruitment Privacy Notice",
      gdprPrivacyConsent:
        "I, {{who}}, hereby consent to the processing of my personal data by rinf.tech in accordance with the General Data Protection Regulation (GDPR) and any applicable national laws. I understand that this includes the collection, storage, and use of my personal information.",
      gdprContent:
        "The following terms and conditions govern all use of the gdpr.eu website and all content, services and products available at or through the website (taken together, the “Website”). The Website is owned and operated by Proton Technologies AG (the “Company”). The Website is offered subject to your acceptance without modification of all the terms and conditions contained herein and all other operating rules, policies and procedures that may be published from time to time on the Website (taken together, the “Agreement”).",
      applicationSubmittedTitle: "Application submitted",
      applicationSubmittedDescription:
        "Thank you for applying to rinf.tech! We have received your application will be reviewing it shortly.",
      location: "Location",
      goback: "Go Back",
      confidentialityInfo:
        "* All applications are strictly confidentialf. We will not disclose any private information without having your approval.",
      linkedinJob: "See job on LinkedIn",
      jobApplyErrorHeader:
        "There was an error while submitting your application",
      jobApplyErrorAlreadyApplied:
        "You have already applied to this job opening.",
      errorGeneric: "Whoops",
      gdprPrivacyError:
        "There was an error while submitting your GDPR consent. Please try again later.",
      jobAlertSubscribe:
        "Job Alert",
      jobListMenuTitle:"Didn't find any open role?",
      jobListMenuOpenApplication:"Feel free to send your CV anyway, maybe it works out!",
      jobListMenuOpenApplicationButton:"Submit Application",
      jobListMenuAlert:"Or create a job alert to receive regular updates of rinf.tech's opportunities matching your interest.",
      jobListMenuAlertButton:"Job Alert",
      jobAlertConsent: "I hereby give my consent to receive notifications regarding job postings and employment opportunities via email. I understand that these communications could be tailored to my job interests and career profile if it is shared with the company. For detailed information about how we handle your personal data, please refer to our",
      privacyNoticeJobAlert: "Privacy Notice."
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
